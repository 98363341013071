import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useQuestionList() {
  // Use toast
  const toast = useToast()

  const refQuestionListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'question', label: 'Question', sortable: true },
    { key: 'active', label: 'Active', sortable: true },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalQuestions = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('question')
  const isSortDirDesc = ref(false)

  const dataMeta = computed(() => {
    const localItemsCount = refQuestionListTable.value ? refQuestionListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalQuestions.value,
    }
  })

  const refetchData = () => {
    refQuestionListTable.value.refresh()
  }

  watch([currentPage, perPage], () => {
    refetchData()
  })

  const fetchQuestions = (ctx, callback) => {
    store
      .dispatch('app-question/fetchQuestions', {
        limit: perPage.value,
        page: currentPage.value,
        sort: `q.${sortBy.value}`,
        direction: isSortDirDesc.value ? 'desc' : 'asc',
      })
      .then(response => {
        const { questions, meta } = response.data

        callback(questions)
        totalQuestions.value = meta.total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching walkaround checks\' list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveQuestionActiveVariant = active => {
    if (active === true) return 'primary'
    if (active === false) return 'warning'
    return null
  }

  const resolveQuestionActiveIcon = active => {
    if (active === true) return 'CheckIcon'
    if (active === false) return 'XIcon'
    return null
  }

  return {
    fetchQuestions,
    tableColumns,
    perPage,
    currentPage,
    totalQuestions,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refQuestionListTable,

    resolveQuestionActiveVariant,
    resolveQuestionActiveIcon,
  }
}
