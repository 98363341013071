<template>
  <b-sidebar
    id="edit-question-sidebar"
    :visible="isEditQuestionSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-edit-question-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add Question
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(handleUpdateQuestionInfo)"
        >
          <b-form-group
            label="Question"
            label-for="question"
          >
            <validation-provider
              #default="{ errors }"
              name="Question"
            >
              <b-form-textarea
                id="textarea-default"
                v-model="newQuestionData.question"
                placeholder="Question"
                rows="3"
                max-rows="30"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            label-for="active"
          >
            <b-form-checkbox
              v-model="newQuestionData.active"
              class="mt-50 custom-control-warning"
              :value="true"
              :unchecked-value="false"
            >
              Active
            </b-form-checkbox>
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="isLoading"
            >
              <b-spinner
                v-if="isLoading"
                small
                class="mr-50"
              />
              {{ isLoading ? "Updating..." : "Update" }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="flat-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>

  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormInput,
  BBadge,
  BFormGroup,
  BCard,
  BCardHeader,
  BCardBody,
  BButton,
  BSpinner,
  BFormTextarea,
  BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, integer } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'

export default {
  components: {
    BSidebar,
    BForm,
    BFormInput,
    flatPickr,
    BCard,
    BCardBody,
    BCardHeader,
    BBadge,
    BSpinner,
    BFormGroup,
    BButton,
    BFormTextarea,
    BFormCheckbox,
    // Form Validation
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isEditQuestionSidebarActive',
    event: 'update:is-edit-question-sidebar-active',
  },
  props: {
    isEditQuestionSidebarActive: {
      type: Boolean,
      required: true,
    },
    questionData: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      required,
      integer,
      newQuestionData: this.$props.questionData ? this.$props.questionData : {},
      isLoading: false,
    }
  },
  methods: {
    handleUpdateQuestionInfo() {
      const questionData = {
        question: this.newQuestionData.question,
        active: this.newQuestionData.active,
        model: this.newQuestionData.model,
      }
      const questionId = this.questionData ? this.questionData.id : null

      if (questionId) return this.updateQuestion(questionId, questionData)
      return this.addQuestion(questionData)
    },
    updateQuestion(questionId, questionData) {
      this.isLoading = true
      store.dispatch('app-question/updateQuestion', { questionId, questionData })
        .then(response => {
          this.newQuestionData = response.data
          this.$emit('updateQuestionInfo', this.newQuestionData)
          this.$emit('update:is-edit-question-sidebar-active', false)
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Question updated',
              icon: 'CheckSquareIcon',
              variant: 'success',
              text: 'You have successfully updated this question.',
            },
          })
          this.isLoading = false
        })
        .catch(err => {
          Object.entries(err.response.data.errors).forEach(errors => {
            const field = errors[0]
            const messages = errors[1]
            const toUppercase = str => str.toUpperCase()
            messages.forEach(errorMessage => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `${field.replace(/[A-Z]/g, ' $&').replace(/^./, toUppercase)}`,
                  icon: 'XIcon',
                  variant: 'warning',
                  text: errorMessage,
                },
              })
            })
          })

          if (questionId) {
            store.dispatch('app-question/fetchQuestion', { id: questionId })
              .then(response => {
                this.$emit('updateQuestionInfo', response.data)
                this.newQuestionData = response.data
              })
          }

          this.$emit('update:is-edit-question-sidebar-active', false)
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Question update failed',
              icon: 'XIcon',
              variant: 'danger',
              text: err.response.data.message || 'An error occurred while updating this question.',
            },
          })
          this.isLoading = false
        })
    },
    addQuestion(questionData) {
      this.isLoading = true
      store.dispatch('app-question/addQuestion', { questionData })
        .then(response => {
          this.$router.push({ name: 'questions-view', params: { id: response.data.id } })
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Question added',
              icon: 'CheckSquareIcon',
              variant: 'success',
              text: 'You have successfully added a new question.',
            },
          })

          this.isLoading = false
        })
        .catch(err => {
          Object.entries(err.response.data.errors).forEach(errors => {
            const field = errors[0]
            const messages = errors[1]
            const toUppercase = str => str.toUpperCase()
            messages.forEach(errorMessage => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `${field.replace(/[A-Z]/g, ' $&').replace(/^./, toUppercase)}`,
                  icon: 'XIcon',
                  variant: 'warning',
                  text: errorMessage,
                },
              })
            })
          })

          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error adding new question',
              icon: 'XIcon',
              variant: 'danger',
              text: err.response.data.message || 'An error occurred while adding this question.',
            },
          })
          this.isLoading = false
        })
    },
  },
  setup(props, { emit }) {
    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    return {
      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#edit-question-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
