import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchQuestions(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/vehicleCheckQuestions', { params: { ...queryParams, showActive: false } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchQuestion(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/vehicleCheckQuestions/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteQuestion(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/vehicleCheckQuestions/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    updateQuestion(ctx, { questionId, questionData }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/vehicleCheckQuestions/${questionId}`, questionData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addQuestion(ctx, {questionData}) {
      return new Promise((resolve, reject) => {
        axios
          .post('/vehicleCheckQuestions', questionData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    toggleQuestionActive(ctx, { id, active }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/vehicleCheckQuestions/${id}/active`, { active })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
